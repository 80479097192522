import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Routes, Route, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/DownloadOutlined'
import SyncIcon from '@mui/icons-material/SyncOutlined'
import SaveIcon from '@mui/icons-material/SaveTwoTone'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import useDownloadJob, { DownloadJobProvider } from 'hooks/useDownloadJob'
import { CONDITION_TYPE, ID_TYPE } from 'entities/segment'
import { TRIGGERS, hasBatchQuery } from 'entities/segment'

import { confirm } from 'components/Confirm'
import DetailPageMenuList, { DetailPageMenuButton } from 'components/DetailPageMenuList'
import { showError } from 'components/ErrorDialog'
import { Form, Select, SubmitButton, TextField } from 'components/Form'
import { filter } from 'components/GroupFilter'
import Loading from 'components/Loading'
import { prompt } from 'components/Prompt'
import PublishSettingDialog from 'components/PublishSettingDialog'
import { showSnackbar } from 'components/Snackbar'
import useJob, { JOB_MODE, JobProvider } from 'hooks/useJob'
import useSegment, { SegmentProvider } from 'hooks/useSegment'
import useAuth from 'lib/auth'
import { addDoc, getDocs, updateDoc, useDoc, where } from 'lib/firestore'
import { httpsCallable } from 'lib/functions'
import { download } from 'utils'
import dayjs from 'dayjs'

import Condition from './Condition'
import DownloadHistory from './DownloadHistory'
import ExportDialog from './ExportDialog'
import IMSHistory from './IMSHistory'
import Preview from './Preview'
import QueryError from './QueryError'

const STATUS = Object.freeze({
  PENDING: 'PENDING', // 処理待ち
  READY: 'READY', // 実行待ち
  PROCESSING: 'PROCESSING', // 処理中
  RETRY: 'RETRY', // リトライ
  SUCCESS: 'SUCCESS', // 成功
  ERROR: 'ERROR', // エラー
})

const defaultValues = {
  trigger: 'dynamic',
  condition: { operator: 'AND', filters: [] },
  state: STATUS.PENDING,
}

const showMessageIfNeeded = segment => {
  if (hasBatchQuery(segment)) {
    showSnackbar(
      '実行に時間がかかる条件が含まれています。処理が完了するまでしばらくお待ち下さい。',
      { autoHideDuration: 5000 }
    )
  }
}

const makeCopy = item => {
  return {
    ...item,
    id: null,
    name: `${item.name} のコピー`,
    job: null,
    result: null,
  }
}

function SegmentDetail() {
  const [open, setOpen] = useState(false)
  const [openExportDialog, setOpenExportDialog] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const params = useParams()
  const { item } = useDoc(`segments/${Boolean(params.id) ? params.id : ''}`)
  const { registerDownloadJob } = useDownloadJob()
  const { registerJob, running, job } = useJob()
  const [previewResult, setPreviewResult] = useState(null)
  const { user } = useAuth()
  const form = useForm({ defaultValues })
  const navigate = useNavigate()
  const data = item || {}
  const { items } = useSegment()
  const hasDependencies =
    params.id && items.some(x => x.condition.filters.some(y => params.id === y.segment?.id))
  const location = useLocation()
  const canExport = item?.idType === 'serial' && !form.formState.isDirty

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (item) {
      form.reset(item)
    }
  }, [item, form])

  useEffect(() => {
    if (location.state?.item) {
      form.reset(makeCopy(location.state.item))
    }
  }, [location.state, form])

  const handleUpdate = async values => {
    const settings = await prompt(PublishSettingDialog, data)
    if (!settings) return

    // 承認済みかつ未配信のメール配信設定が存在する場合は更新時に confirm を表示する
    const docs = await getDocs(
      'target-mails',
      where('target.id', '==', data.id),
      where('approval.group', '==', true),
      where('approval.admin', '==', true),
      where('state', '!=', 'SUCCESS')
    )
    if (docs.length > 0) {
      if (
        !(await confirm({
          title: 'セグメントを更新してもよろしいですか？',
          body: 'このセグメントは承認済みのメール配信設定で参照されています。セグメントの変更によりメール配信に影響を与える可能性があります。',
        }))
      ) {
        return
      }
    }

    if (
      !hasDependencies ||
      (await confirm({
        title: 'セグメントを更新してもよろしいですか？',
        body: 'このセグメントは他のセグメントから参照されているため、参照先のセグメントに影響を与えます。',
      }))
    ) {
      delete values.breakdown
      if (values.trigger === 'static') {
        showMessageIfNeeded(values)
      }
      return updateDoc(`segments/${data.id}`, { ...values, ...settings })
        .then(() => setOpen(false))
        .catch(error => form.setError('network', { message: error.message }))
    }
  }

  const handleAdd = async values => {
    const settings = await prompt(PublishSettingDialog)
    if (!settings) return

    delete values.breakdown
    if (values.trigger === 'static') {
      showMessageIfNeeded(values)
    }
    return addDoc('segments', { ...values, ...settings, createdBy: user.id, deleted: false })
      .then(() => setOpen(false))
      .catch(error => form.setError('network', { message: error.message }))
  }

  const handlePreview = async values => {
    showMessageIfNeeded(values)
    return registerJob({ segment: { ...data, ...values }, mode: JOB_MODE.PREVIEW })
      .then(data => {
        console.log('-- 以下と等価の SQL 文が実行されました --')
        console.log(data.result.sql)
        setPreviewResult(data.result)
        form.reset(values)
      })
      .catch(error => showError({ message: <QueryError error={error} /> }))
  }

  const handleChangeIdType = e => {
    form.setValue('condition', defaultValues.condition)
    form.setValue('defaultQueries', {})
  }

  const handleExport = async result => {
    if (result.type === 'IMS') {
      return exportToIMS(data, result.values)
    } else if (result.type === 'DOWNLOAD') {
      return handleDownload(data, result.values)
    }
  }

  const handleDownload = async (segment, values) => {
    if (segment.trigger === 'dynamic' && hasBatchQuery(segment)) {
      showSnackbar(
        '実行に時間がかかる条件が含まれています。処理が完了するまでしばらくお待ち下さい。',
        { autoHideDuration: 5000 }
      )
    }

    setDownloading(true)

    return httpsCallable('callable-ip-validate')()
      .then(() =>
        registerDownloadJob({ ...values, segment, createdBy: { id: user.id, email: user.email } })
      )
      .then(job => {
        download(job.result.url)
        setDownloading(false)
      })
      .catch(error => {
        console.error(error)
        showSnackbar(`クエリの実行に失敗しました。${error.message}`)
      })
  }

  const exportToIMS = async (segment, values) => {
    return addDoc(`segments/${data.id}/ims-jobs`, {
      ...values,
      segment,
      state: 'PENDING',
      createdBy: { id: user.id, email: user.email },
    })
      .then(() => showSnackbar('IMS 連携ジョブの登録に成功しました'))
      .catch(e => showSnackbar(`連携に失敗しました。${e.message}`))
  }

  if (params.id && !data.id) {
    return <Loading />
  }

  if (params.id && filter(items, '', user).every(x => x.id !== data.id)) {
    return <Navigate to="../" replace />
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('/segments') }}
      sx={{ '& .MuiPaper-root': { bgcolor: 'background.default' } }}
    >
      <Form form={form}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              disabled={form.formState.isSubmitting || running}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              セグメント作成
            </Typography>
            <DetailPageMenuList item={item}>
              <DetailPageMenuButton
                startIcon={<DownloadIcon size={12} />}
                onClick={() => navigate('./downloads')}
                disabled={!Boolean(data.id) || data?.idType !== 'serial'}
              >
                ダウンロード履歴
              </DetailPageMenuButton>
              <DetailPageMenuButton
                startIcon={<SyncIcon size={12} />}
                onClick={() => navigate('./ims-histories')}
                disabled={!Boolean(data.id) || data?.idType !== 'serial'}
              >
                IMS 連携履歴
              </DetailPageMenuButton>
            </DetailPageMenuList>
            <Tooltip
              title="SERIAL ID のセグメントを保存後にこのアクションを選択できます。"
              disableFocusListener={canExport}
              disableHoverListener={canExport}
              disableTouchListener={canExport}
            >
              <Box>
                <Button
                  variant="text"
                  sx={{ color: theme => theme.palette.primary.main, mr: 1 }}
                  onClick={() => setOpenExportDialog(true)}
                  loading={downloading}
                  disabled={!canExport}
                >
                  エクスポート
                </Button>
              </Box>
            </Tooltip>
            <SubmitButton
              variant="text"
              sx={{ color: theme => theme.palette.primary.main, mr: 1 }}
              onClick={form.handleSubmit(handlePreview)}
              loading={running && job?.mode === 'PREVIEW'}
            >
              プレビュー
            </SubmitButton>
            <SubmitButton
              startIcon={<SaveIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(Boolean(data.id) ? handleUpdate : handleAdd)}
              disabled={
                (Boolean(data.group) && !user.groups.includes(data.group)) ||
                (form.getValues('condition.filters').some(x => x.type === CONDITION_TYPE.SQL) &&
                  form.formState.isDirty &&
                  Boolean(form.formState.dirtyFields.condition))
              }
              loading={running ? false : null}
            >
              保存
            </SubmitButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        <Box sx={{ display: 'flex' }}>
          <Stack spacing={2} sx={{ flex: 1, p: 5 }}>
            <TextField
              label="セグメント名"
              name="name"
              rules={{
                required: true,
                maxLength: 100,
                validate: v => {
                  if (items.filter(x => x.id !== data.id).some(x => x.name === v)) {
                    return '既に存在する名前です。'
                  } else {
                    return true
                  }
                },
              }}
              fullWidth
              placeholder="新しいセグメント"
              errorText={form.formState.errors?.name?.message || '100 文字以内で入力してください'}
            />
            <Select
              name="trigger"
              label="トリガー"
              fullWidth
              sx={{ '& .MuiTypography-caption': { display: 'none' } }}
            >
              {TRIGGERS.map(({ name, label, description }) => (
                <MenuItem
                  key={name}
                  value={name}
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  {label}
                  <Typography variant="caption" color="textSecondary">
                    {description}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <Select
              name="idType"
              label="ID 種別"
              fullWidth
              defaultValue={ID_TYPE.SERIAL.value}
              onChange={handleChangeIdType}
            >
              {Object.values(ID_TYPE).map(x => (
                <MenuItem key={x.value} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
            <Condition name="condition" />
            {form.formState.errors.network && (
              <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
            )}
          </Stack>
          <Preview
            latestResult={item?.result?.table}
            count={previewResult?.count}
            onReload={form.handleSubmit(handlePreview)}
          />
        </Box>
      </Form>
      <Routes>
        <Route path="downloads" element={<DownloadHistory />} />
        <Route path="ims-histories" element={<IMSHistory />} />
      </Routes>
      {openExportDialog && (
        <ExportDialog
          defaultValues={{
            ims: {
              sendAt: dayjs().format('YYYY-MM-DDTHH:mm'),
              listName: `NGMA_${data.name}_`,
              listPurpose: data.memo ?? null,
            },
          }}
          onSubmit={handleExport}
          onClose={() => setOpenExportDialog(false)}
        />
      )}
    </Dialog>
  )
}

export default function SegmentDetailWrapper() {
  return (
    <SegmentProvider>
      <DownloadJobProvider>
        <JobProvider>
          <SegmentDetail />
        </JobProvider>
      </DownloadJobProvider>
    </SegmentProvider>
  )
}
