import { Controller, FormProvider, useController, useFormContext } from 'react-hook-form'

import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import MuiCheckbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import MuiSwitch from '@mui/material/Switch'
import MuiTextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import Button from './Button'

const isError = (errors, name) => {
  return Boolean(getError(errors, name))
}

export const getError = (errors, name) => {
  const [x, ...y] = name.split('.')
  const error = errors[x]
  if (!error) {
    return null
  }
  if (y.length === 0) {
    return error
  }
  return getError(errors[x], y.join('.'))
}

const getErrorMessage = error => {
  if (!error) return null
  if (error.type === 'required') return '必須項目です'
  if (error.message === '') return null
  return error.message
}

export function Form({ form, onSubmit, children }) {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off" noValidate>
        {children}
      </form>
    </FormProvider>
  )
}

export function TextField({
  name,
  rules,
  errorText,
  defaultValue = '',
  options,
  convert,
  SelectProps = {},
  ...props
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const { field } = useController({ name, control, rules, defaultValue })

  if (options) {
    const Component = SelectProps.native ? props => <option {...props} /> : MenuItem
    props.children = options.map(x => {
      const value = typeof x === 'string' ? x : x.value
      const label = typeof x === 'string' ? x : x.label
      return <Component key={value} value={value} children={label} />
    })
    props.select = true
    props.SelectProps = {
      displayEmpty: options.some(x => x === '' || x.value === ''),
      ...SelectProps,
    }
  }

  return (
    <MuiTextField
      {...field}
      {...props}
      onChange={e => {
        let v = e.target.value
        if (convert) {
          v = convert(v)
        } else if (props.type === 'number') {
          if (v !== '') {
            v = Number(v)
          }
        }
        field.onChange(v)
        if (props.onChange) props.onChange(e)
      }}
      error={isError(errors, name)}
      helperText={
        (!isError(errors, name) && props.helperText) ||
        (isError(errors, name) && (getErrorMessage(getError(errors, name)) || errorText))
      }
      required={Boolean(rules?.required)}
    />
  )
}

export function Select({
  name,
  rules,
  label,
  disabled,
  sx,
  fullWidth,
  defaultValue = '',
  children,
  onChange,
  ...props
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl sx={sx} fullWidth={fullWidth} disabled={disabled} error={isError(errors, name)}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MuiSelect
            {...field}
            {...props}
            label={label}
            onChange={e => {
              field.onChange(e)
              if (onChange) onChange(e)
            }}
          >
            {children}
          </MuiSelect>
        )}
      />
    </FormControl>
  )
}

export function Switch({ name, label, sx }) {
  const { control } = useFormContext()

  return (
    <FormControl sx={sx}>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormGroup>
            <FormControlLabel
              control={<MuiSwitch {...field} checked={field.value} />}
              label={label}
            />
          </FormGroup>
        )}
      />
    </FormControl>
  )
}

export function Checkbox({ name, label, defaultValue = false, disabled }) {
  const { control } = useFormContext()

  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormGroup>
            <FormControlLabel
              control={<MuiCheckbox {...field} checked={field.value} disabled={disabled} />}
              label={label}
            />
          </FormGroup>
        )}
      />
    </FormControl>
  )
}

// FIXME: 現状の利用状況をもとにリファクタリングする
export function Autocomplete({
  name,
  rules,
  label,
  sx,
  fullWidth,
  optionKeys = [],
  onChange,
  InputLabelProps = {},
  renderOptionKeys, // 表示優先度を変更したい時に指定
  placeholder,
  type = 'string',
  ...props
}) {
  const { control } = useFormContext()

  if (optionKeys.length > 0) {
    const [key] = optionKeys
    const [renderPrimaryKey, renderSecondaryKey] = renderOptionKeys || optionKeys
    props.filterOptions = createFilterOptions({
      stringify: x =>
        Object.entries(x)
          .filter(([k, v]) => (renderOptionKeys || optionKeys).includes(k))
          .map(([k, v]) => v)
          .join(' '),
    })
    props.getOptionLabel = Boolean(props.getOptionLabel)
      ? props.getOptionLabel
      : option => option[renderPrimaryKey] || ''
    props.isOptionEqualToValue = (o, v) => v[key] === o[key]
    if (!props.renderOption) {
      props.renderOption = (props, option) => (
        <li {...props} style={{ flexDirection: 'column', alignItems: 'start' }}>
          {option[renderPrimaryKey]}
          <Typography variant="caption" color="textSecondary">
            {Array.isArray(option[renderSecondaryKey])
              ? option[renderSecondaryKey].join(',')
              : option[renderSecondaryKey]}
          </Typography>
        </li>
      )
    }
  }

  return (
    <FormControl sx={sx} fullWidth={fullWidth}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={null}
        render={({ field, fieldState }) => (
          <MuiAutocomplete
            {...field}
            {...props}
            onChange={(_, v) => {
              if (onChange) onChange(v)
              field.onChange(v)
            }}
            onInputChange={(_, v, reason) => {
              if (props.freeSolo && !props.multiple && reason === 'input') {
                if (optionKeys.length === 2 && typeof v === 'string') {
                  const [k1, k2] = optionKeys
                  field.onChange({ [k1]: v, [k2]: v })
                } else {
                  if (type === 'number') {
                    field.onChange(Number(v))
                  } else {
                    field.onChange(v)
                  }
                }
              }
            }}
            renderInput={params => (
              <MuiTextField
                {...params}
                label={label}
                type={type}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                placeholder={placeholder}
                required={Boolean(rules?.required)}
                InputLabelProps={InputLabelProps}
              />
            )}
            getOptionLabel={option => {
              const label = Boolean(props.getOptionLabel) ? props.getOptionLabel(option) : option
              if (typeof label === 'number') {
                return label.toString()
              }
              return label
            }}
          />
        )}
      />
    </FormControl>
  )
}

export function SubmitButton({ ...props }) {
  const { formState } = useFormContext()
  const loading = props.loading || formState.isSubmitting
  const disabled = props.loading || props.disabled || formState.isSubmitting
  return <Button loading={loading} disabled={disabled} {...props} />
}
